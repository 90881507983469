<template>

  <v-dialog
    id="login"
    v-model="dialog"
    max-width="400"
    min-width="350"
    scrollable
  >
    <v-overlay opacity="0.2" z-index="0" color="white"></v-overlay>
    <v-card>
      <v-card-title class="title pb-4">
        Anmelden
      </v-card-title>
      <v-card-text align="center">

        <v-text-field
          v-model="username"
          name="login"
          label="E-Mail"
          :color="'red'"
          :rules="[v => true]"
          persistent-hint
          outlined
          dense
        />
        <v-text-field
          v-model="password"
          type="password"
          name="password"
          label="Passwort"
          :color="'red'"
          :rules="[v => !!v]"
          outlined
          required
          dense
        />
        <div style="text-align: left"><a class="box-link" to="/forgot">Passwort zurücksetzen</a></div>
        <br>
          <v-btn
            id="login-btn"
            color="#005F8C"
            depressed
            dark
            @click.stop="login"
          >Anmelden</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="stylus">
.title
  color: #005F8C
</style>
<script>

import router from '.././router'
// import store from '.././store'
import { SET_USER_DATA } from '@/store/mutation-types'

export default {
  name: 'Login',
  components: {
  },

  data () {
    return {
      dialog: true,
      username: 'demo',
      password: 'demo'
    }
  },
  methods: {
    login: function () {
      this.$store.commit(SET_USER_DATA, { user: this.username, password: this.password })
      router.push({ name: 'management.dashboard.owner' })
    }
  }
}
</script>
