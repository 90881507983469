<template>

  <v-dialog
    id="register"
    v-model="dialog"
    max-width="400"
    min-width="350"
    scrollable
  >
    <v-overlay opacity="0.2" z-index="0" color="white"></v-overlay>
    <v-card>
      <v-card-title class="title pb-4">
        Registrieren
      </v-card-title>
      <v-card-text align="center" class="pt-1">
        <v-text-field
          v-model="email"
          name="email"
          label="E-Mail *"
          :rules="[v => true]"
          persistent-hint
          outlined
          dense
        />
        <v-text-field
          v-model="password"
          type="password"
          name="password"
          label="Passwort *"
          outlined
          required
          dense
        />
        <v-text-field
          v-model="password2"
          type="password2"
          name="password2"
          label="Passwort wiederholen *"
          outlined
          required
          dense
        />
        <br>
        <v-btn
          color="#005F8C"
          depressed
          dark
          @click.stop="register"
        >Registrieren</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="stylus">
.title
  color: #005F8C
</style>
<script>

import router from '.././router'
import { SET_USER_DATA } from '@/store/mutation-types'

export default {
  name: 'Register',
  components: {
  },

  data () {
    return {
      email: '',
      password: '',
      password2: '',
      dialog: true
    }
  },
  methods: {
    register: function () {
      this.$store.commit(SET_USER_DATA, { user: this.email, password: this.password })
      router.push({ name: 'management.dashboard' })
    }
  }
}
</script>
