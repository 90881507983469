<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-card class="overflow-hidden">
        <v-toolbar
          fixed
          class=""
          color="primary"
          style="color: white"
          >
          <v-img width="20" max-width="30" src="@/assets/logo_key_white.png"></v-img>
          <v-toolbar-title class="ml-3">Verwaltung TausendTüren</v-toolbar-title>
          <v-spacer></v-spacer>
          <span class="hidden-sm-and-down" style="font-size: 0.8em;">Demo:</span>
          <v-toolbar-items class="hidden-sm-and-down ml-2">
            <v-btn text style="color: white;" :to="{ name: 'management.dashboard.owner' }">VerwalterIn</v-btn>
            <v-btn text style="color: white;" :to="{ name: 'management.dashboard.user' }">BenutzerIn</v-btn>
          </v-toolbar-items>
          <v-spacer></v-spacer>

    <v-menu
      left
      :offset-x="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="hidden-md-and-up"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="white">mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item><span style="font-size: 0.8em;">Demo:</span></v-list-item>
        <v-list-item
          v-for="(item, index) in mobileMenu"
          :key="index"
          style="cursor: pointer"
        >
          <v-list-item :to="{name: item.to}">{{ item.title }}</v-list-item>
        </v-list-item>
      </v-list>
    </v-menu>

        </v-toolbar>
        <v-sheet
          id="scrolling-techniques-3"
          class="overflow-y-auto"
        >
          <v-container>
            <router-view></router-view>
          </v-container>
        </v-sheet>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'management-wrapper',
  components: {
  },
  data () {
    return {
      mobileMenu: [
        { title: 'VewalterIn', to: 'management.dashboard.owner' },
        { title: 'BesucherIn', to: 'management.dashboard.user' }
      ]
    }
  },
  computed: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
  },
  watch: {
  }
}
</script>
<style lang='stylus'>

</style>
