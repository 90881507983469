import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Imprint from '../views/Imprint.vue'
import Blog from '../views/Blog.vue'
import Contact from '../views/Contact.vue'
import Register from '../views/Register.vue'

import ManagementWrapper from '../views/management/ManagementWrapper.vue'
import DashboardOwner from '../views/management/DashboardOwner.vue'
import DashboardUser from '../views/management/DashboardUser.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'start',
    component: Home
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/impressum',
    name: 'imprint',
    component: Imprint
  },
  {
    path: '/registrieren',
    name: 'register',
    component: Register
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: Contact
  },
  {
    path: '/verwaltung',
    name: 'management.wrapper',
    component: ManagementWrapper,
    children: [
      {
        path: 'dashboard/owner',
        name: 'management.dashboard.owner',
        component: DashboardOwner
      },
      {
        path: 'dashboard/user',
        name: 'management.dashboard.user',
        component: DashboardUser
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
