<template>
  <v-row justify="center" class="mt-14">
    <v-col cols="12" lg="12">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <h1 class="mb-3">Buchunganfragen</h1>
        </v-col>
        <v-col v-for="res in myReservations" v-bind:key="res.k" cols="12" md="10" lg="8">
          <v-card>
            <v-row>
              <v-col cols="12" md="4" lg="6" class="pt-0">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="getRoomImage(res.room)"
                ></v-img>
                <v-card-title>
                  <span>
                    Anfrage vom {{ momentThis(res.bookingDate) }}<br>Raum "{{ res.room.name }}"
                  </span>
                </v-card-title>
              </v-col>
              <v-col cols="12" md="8" lg="6">
                <v-card-text>
                  <h4>Buchungsstatus</h4>
                  <template v-if="res.confirmed !== null && res.confirmed">
                    <v-icon color="primary" size="24" style="display: inline" class="mr-1">mdi-check-circle-outline</v-icon>
                    Sie haben die Buchung am {{ momentThis(res.answerDate) }} bestätigt.
                  </template>
                  <template v-if="res.confirmed !== null && res.confirmed === false">
                    <v-icon color="secondary" size="24" style="display: inline" class="mr-1">mdi-close-circle-outline</v-icon>
                    Sie haben diese Anfrage am {{ momentThis(res.answerDate) }} abgelehnt.
                  </template>
                  <template v-if="res.confirmed === null">
                    <v-icon size="24" style="display: inline" class="mr-1">mdi-checkbox-blank-circle-outline</v-icon>
                    Sie haben diese Anfrage noch  nicht bearbeitet.
                  </template>
                  <h4 class="mt-2">BucherIn</h4>
                  <v-icon>mdi-account</v-icon> {{ res.user.user }}
                  <v-rating
                    style="display: inline-block"
                    class="pb-1"
                    color="primary"
                    background-color="primary lighten-11"
                    empty-icon="mdi-star-outline"
                    full-icon="mdi-star"
                    half-icon="mdi-star-half-full"
                    half-increments
                    length="5"
                    readonly
                    dense
                    size="24"
                    :value="4"
                  ></v-rating>
                  <h4 class="mt-2">Zeitraum</h4>
                  <v-icon class="mr-1">mdi-clock-start</v-icon>{{ momentThis(res.start) }}
                  <br>
                  <v-icon class="mr-1">mdi-clock-end</v-icon>{{ momentThis(res.end) }}
                  <h4 class="mt-2">Sonstiges</h4>
                  Personenzahl: {{ res.count }}
                  <br>
                  Anfrage: {{ res.comment }}
                </v-card-text>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-btn
                    color="primary"
                    text
                    @click.stop="messageReservationId = res.id; messageDialog = true;"
                  >
                    <v-icon>mdi-message-outline</v-icon>
                    <span class="ml-2">Nachricht<template v-if="res.messages.length > 1">en</template> ({{ res.messages.length }})</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6" align="right">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" :disabled="res.confirmed" @click="confirmReservation(res.id)"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Zusagen</v-btn>
                  <v-btn color="primary" :disabled="!res.confirmed" @click="declineReservation(res.id)"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Absagen</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="10" lg="8">
      <h1>Verwaltung Ihrer Objekte</h1>
      <p style="color: red">Diese Funktion ist noch nicht eingebaut.</p>
      <v-row justify="center">
        <v-col v-for="room in this.myLocations" v-bind:key="room.key" cols="10" md="6" lg="4">
          <v-card class="">
            <v-img
              class="white--text align-end"
              height="200px"
              :src="getRoomImage(room)"
            >
              <v-card-title style="background-color: rgba(1,1,1,0.5);">{{ room.name }} </v-card-title>
              <v-card-text style="background-color: rgba(1,1,1,0.5);">
                <span>{{ room.street }}<br>{{ room.postcode }} {{ room.city }}</span>
              </v-card-text>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

      <v-dialog
        v-if="messageReservationId"
        v-model="messageDialog"
        max-width="600"
        min-width="400"
        max-height="500"
        persistent
        scrollable
        no-click-animation
      >
        <v-card>
          <v-card-title style="background-color: #005F8C; color: white">
            Nachrichtenverlauf mit "{{ dialogMessagesReservation.user.user}}"
            <v-spacer></v-spacer>
            <v-btn
              @click.stop="messageReservationId = null; messageDialog = false;"
              icon
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text ref="messagesContainer">
            <div class="mt-2" v-for="message in (dialogMessagesReservation.messages)" v-bind:key="message.key" :align="message.from === 'owner' ? 'right' : 'left'">
              <v-sheet
                :color="message.from === 'owner' ? 'primary' : 'primary lighten-1'"
                style="color: white"
                elevation="5"
                width="80%"
                rounded="xl"
                class="pa-3"
              >
                <span style="font-size: 0.9em">{{ message.from === 'owner' ? dialogMessagesReservation.room.name : dialogMessagesReservation.user.user }} ({{ momentThis(message.date, 'llll') }})</span>
                <br>
                {{ message.message }}
              </v-sheet>
              <br>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-text-field
              v-model="newMessage"
              class="mt-1 mb-4"
              name="newMessage"
              label="Ihre Nachricht..."
              :rules="[v => true]"
              :persistent-hint="false"
              :hide-details="true"
              outlined
              dense
              v-on:keyup.enter="sendMessage"
              append-outer-icon="mdi-send-outline"
              @click:append-outer="sendMessage"
            />
          </v-card-actions>
          <v-card-actions>
            <v-btn
              color="primary"
              @click.stop="messageReservationId = null; messageDialog = false;"
            >Schließen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-row>
</template>

<script>

import { ADD_MESSAGE, SET_ERROR_MESSAGE, DECLINE_RESERVATION, CONFIRM_RESERVATION } from '@/store/mutation-types'
import store from '../../store'
import moment from 'moment'
// import router from '.././router'
export default {
  name: 'dashboard-owner',
  components: {
  },
  data () {
    return {
      messageReservationId: null,
      messageDialog: false,
      newMessage: null
    }
  },
  computed: {
    myLocations () {
      return store.state?.roomData ?? []
    },
    myReservations () {
      return store.state?.reservations ?? []
    },
    dialogMessagesReservation () {
      if (this.messageReservationId === null) {
        return []
      }
      for (const res of this.myReservations) {
        if (res.id === this.messageReservationId) {
          return res
        }
      }
      return []
    }

  },
  created () {
  },
  mounted () {
  },
  updated () {
    this.$nextTick(() => this.scrollToEnd())
  },
  methods: {
    scrollToEnd: function () {
      if (this.messageReservationId === null) {
        return
      }
      var content = this.$refs.messagesContainer
      content.scrollTop = content.scrollHeight
      console.log('scroll height is ' + content.scrollHeight + ' scroll Top is ' + content.scrollTop)
    },
    momentThis (dt, format = 'llll') {
      moment.locale('de')
      return moment(dt).format(format)
    },
    sendMessage () {
      this.snack(null)
      if (this.newMessage === null || this.newMessage === '') {
        this.snack('Die Nachricht darf nicht leer sein')
        return
      }
      this.$store.commit(ADD_MESSAGE, {
        resId: this.messageReservationId,
        from: 'owner',
        date: new Date(),
        message: this.newMessage
      })
      this.newMessage = ''
    },
    confirmReservation (resId) {
      this.snack(null)
      this.$store.commit(CONFIRM_RESERVATION, resId)
      this.snack('Reservierungsanfrage bestätigt')
    },
    declineReservation (resId) {
      this.snack(null)
      this.$store.commit(DECLINE_RESERVATION, resId)
      this.snack('Reservierungsanfrage abgelehnt')
    },
    snack (message) {
      this.$store.commit(SET_ERROR_MESSAGE, { message: message })
    },
    getRoomImage: function (room) {
      if (room.images && room.images.length > 0) {
        return require('../../assets/images/rooms/' + room.images[0])
      }
      return 'https://picsum.photos/150/180?blur=2'
    },
    randomRating: function () {
      return 4.0// Math.floor(Math.random() * 4) + 1
    }
  },
  watch: {
  }
}
</script>
<style lang='stylus'>

</style>
