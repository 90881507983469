<template>
  <v-container id="home">
    <SearchBar msg="Welcome to Your Vue.js App"/>
  </v-container>
</template>

<script>
// @ is an alias to /src
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'Home',
  components: {
    SearchBar
  }
}
</script>
