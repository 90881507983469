<template>
    <v-row justify="center">
    <!--<v-card
    class="d-flex align-content-center justify-center flex-wrap"
    style="height: calc(100vh - 31px); background-color: unset; overflow: auto"
    elevation="0"
    dialog:
    overlay-color="white"
    overlay-opacity="0.2"
  >-->
  <v-dialog
    id="fillable-fields"
    v-model="searchDialog"
    max-width="1000"
    min-width="350"
    scrollable
    persistent
    overlay-color="white"
    overlay-opacity="0.2"
    no-click-animation
  >
    <v-card color="rgb(255, 255, 255, 0.4)" class="search-card">
      <span class="mb-2 mt-3 search-title">Finden Sie Ihren Raum</span>
      <v-card-text align="center">
        <v-row no-gutters>
          <v-card class="pa-5 pt-1" style="width: 100%">
            <v-text-field
              v-model="searchField"
              color="#000000"
              background-color="#ffffff"
              placeholder="PLZ, Ort, Raumtyp, Anlass, etc."
              prepend-inner-icon="mdi-crosshairs"
              clearable
              hide-details="true"
              @keyup="initiateSearch"
            ></v-text-field>
          </v-card>
          <v-col cols="12" class="mt-2">
            <v-btn
              v-if="false"
              id="btn-search"
              color="primary"
              class="pa-3 mt-2"
              elevation="3"
              @click="initiateSearch"
            >
              Finden
            </v-btn>
          </v-col>
          <v-expand-transition>
            <v-col cols="12" v-if="(displayData && displayData.length > 0) || this.showList">
              <v-list class="mt-3" three-line>
              <v-row class="pl-3 pr-3 pt-3">
                <v-select
                  :items="sizeFilterSelections"
                  label="Grundfläche"
                  class="select-filter"
                  v-model="sizeFilter"
                  @input="filterRooms"
                ></v-select>
                <v-select
                  :items="['Tische', 'Stühle']"
                  label="Equipment"
                  class="select-filter"
                  v-model="equipmentFilter"
                  multiple
                  @input="filterRooms"
                ></v-select>
                <v-select
                  :items="['Beschallung', 'WLAN']"
                  label="Technische Ausstattung"
                  class="select-filter"
                  multiple
                  v-model="technologyFilter"
                  @input="filterRooms"
                ></v-select>
                <v-select
                  :items="['Catering', 'Shuttle']"
                  label="Zusatzleistungen"
                  class="select-filter"
                  v-model="servicesFilter"
                  @input="filterRooms"
                  multiple
                ></v-select>
              </v-row>
                <template v-for="(item, index) in displayData">
                  <v-list-item
                    :key="item.name"
                  >
                    <v-list-item-avatar @click.stop="openLocationDetails(item)" class="link" height="150" width="30%" style="min-width: 180px" rounded="0">
                      <v-img v-if="item.images" :src="require('../assets/images/rooms/' + item.images[0])" />
                      <v-img v-else src="https://picsum.photos/150/180?blur=2" />
                    </v-list-item-avatar>
                    <v-list-item-content style="text-align:left; height: 100%">
                      <v-col>
                        <v-list-item-title @click.stop="openLocationDetails(item)" class="link" style="color: #005F8C"><b>{{ item.name }}</b></v-list-item-title>
                        <v-list-item-subtitle class="mt-1"><b>{{ item.postcode }} {{ item.city }}</b>, {{ item.street }}</v-list-item-subtitle>
                        <br>
                        <p v-if="item.equipment">
                          &#x2713; Equipment vorhanden
                        </p>
                        <p v-if="item.technology">
                          &#x2713; Technische Ausstattung
                        </p>
                        <p v-if="item.services">
                          &#x2713; Zusazleistungen
                        </p>
                        <h4 class="mb-4" style="color: black; padding-bottom: -10px">Andere Nutzer empfehlen diesen Raum für</h4>
                        <v-chip
                          v-for="recommendations in item.recommendedFor"
                          v-bind:key="recommendations.key"
                          class="mr-2 mt-1"
                          outlined
                          small
                        >
                          {{ recommendations }}
                        </v-chip>
                      </v-col>
                      <v-col style="text-align:right; height: 100%">
                        <v-list-item-subtitle>
                          <v-btn color="#005F8C" style="color: white" @click.stop="openLocationDetails(item)">
                            Details
                          </v-btn>
                        </v-list-item-subtitle>
                        <div v-if="item.room">
                          <br>
                          Grundfläche: <b>{{ item.room.space }} qm</b><br>
                          <b>(max. {{ item.room.capacity }} Personen)</b><br>
                          <br>
                        </div>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="index" :inset="true"></v-divider>
                </template>
              </v-list>
              <div v-if="displayData" style="background-color: white; margin-bottom:2em;">
                {{ displayData.length }} Suchergebnisse<br><br>
              </div>
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
      <v-dialog
        v-model="detailsDialog"
        max-width="1000"
        min-width="300"
        persistent
        scrollable
        no-click-animation
      >
        <v-card>
          <v-card-title class="headline room-header">
            {{ currentLocation.name }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-img v-if="currentLocation.images" :src="require('../assets/images/rooms/' + currentLocation.images[selectedImage])" style="width:calc(100% - 0.1em);" contain />
                <br>
                <v-row style="margin-left: auto; margin-right:auto; width: 100%">
                  <v-img v-for="(item, index) in currentLocation.images" :key="item" :src="require('../assets/images/rooms/' + currentLocation.images[index])" style="margin:0.05em;cursor:pointer;max-width:calc(33.33% - 0.1em)" contain @click="selectedImage=index" />
                </v-row>
              </v-col>
              <v-col cols="8">
                <div v-if="currentLocation.room">
                  <h3>Raum</h3>
                  Grundfläche: {{ currentLocation.room.space }} qm<br>
                  Kapazität: max. {{ currentLocation.room.capacity }} Personen<br>
                  <span v-if="currentLocation.room.height">
                    Raumhöhe: {{ currentLocation.room.height }} qm<br>
                  </span>
                  <span v-if="currentLocation.room.additional">
                    <br>
                    ({{ currentLocation.room.additional }})
                  </span>
                  <br>
                  <br>
                </div>
                <div v-if="currentLocation.equipment">
                  <h3>Ausstattung</h3>
                  <ul>
                    <li v-for="item in currentLocation.equipment" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  <br>
                </div>
                <div v-if="currentLocation.technology">
                  <h3>Technik</h3>
                  <ul>
                    <li v-for="item in currentLocation.technology" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  <br>
                </div>
                <div v-if="currentLocation.services">
                  <h3>Zusatzleistungen</h3>
                  <ul>
                    <li v-for="item in currentLocation.services" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  <br>
                </div>
                <h3>Kontakt</h3>
                <div v-if="currentLocation.contact">
                  <span style="color: #005F8C">Name:</span> {{ currentLocation.contact.name }}, {{ currentLocationContact }}<br>
                  <span style="color: #005F8C">Telefon:</span> {{ currentLocation.contact.phone }}<br>
                  <span style="color: #005F8C">Mail:</span> <a :href="'mailto:' + currentLocation.contact.mail">{{ currentLocation.contact.mail }}</a>
                </div>
                <br>
                <h3>Preise</h3>
                <div v-if="currentLocation.price ? currentLocation.price.threeHours !== '' : false">
                  <v-btn
                    color="primary"
                    text
                  >
                    {{ currentLocation.price.threeHours }} € pro 3h
                  </v-btn>
                </div>
                <div v-if="currentLocation.price ? currentLocation.price.day !== '' : false">
                  <v-btn
                    color="primary"
                    text
                  >
                    {{ currentLocation.price.day }} € pro Tag
                  </v-btn>
                </div>
                <div v-if="currentLocation.price ? currentLocation.price.month !== '' : false">
                  <v-btn
                    color="primary"
                    text
                  >
                    {{ currentLocation.price.month }} € pro Monat
                  </v-btn>
                </div>
                <br>
                <h3 class="mb-4">Andere Nutzer empfehlen diesen Raum für</h3>
                <v-chip
                  v-for="item in currentLocation.recommendedFor"
                  v-bind:key="item.key"
                  class="mr-2"
                  outlined
                >
                  {{ item }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              text
              @click="openBookRoomDialog"
            >
              Buchen
            </v-btn>

            <v-btn
              color="primary"
              text
              @click="closeLocationDetails"
            >
              Zurück
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="bookRoomDialog"
        max-width="600"
        min-width="400"
        persistent
        scrollable
        no-click-animation
      >
        <v-card>
          <v-card-title class="headline room-header">
            Buche "{{ currentLocation.name }}""
          </v-card-title>
          <v-card-text>
            <v-row>
            <v-col cols="10" md="6">
              <v-datetime-picker
                label="Von"
                dateFormat="dd.MM.yyyy,"
                timeFormat="HH:mm"
                clearText="Abbrechen"
                okText="Bestätigen"
                v-model="bookingStart"
                id="bookingStart"
                :text-field-props="textFieldProps"
                :date-picker-props="dateProps"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar-month</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="10" md="6">
              <v-datetime-picker
                  label="Bis"
                  dateFormat="dd.MM.yyyy,"
                  timeFormat="HH:mm"
                  clearText="Abbrechen"
                  okText="Bestätigen"
                  id="bookingEnd"
                  v-model="bookingEnd"
                  :text-field-props="textFieldProps"
                  :date-picker-props="dateProps"
                  :time-picker-props="timeProps"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar-month</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock-time-four-outline</v-icon>
                  </template>
                </v-datetime-picker>
            </v-col>
            <v-col cols="10" md="6">
              <v-text-field
                v-model="bookingCount"
                name="bookingCount"
                label="Anzahl Gäste"
                :rules="[v => true]"
                :persistent-hint="false"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="bookingComment"
                name="bookingComment"
                label="Ihre Botschaft"
                :persistent-hint="false"
                outlined
                dense
              ></v-textarea>
            </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              text
              @click="saveBookingRequest"
            >
              Absenden
            </v-btn>

            <v-btn
              color="primary"
              text
              @click="closeBookRoomDialog"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>
<style lang="stylus">
.custom-color
  background-color: #ff00ff
  border-color: #00ff00
.search-card
  text-align: center
  .search-title
    font-size: 2em
    color: #005F8C
.link
  cursor: pointer
.room-header
  color: #005F8C
.select-filter
  margin-left: 1em
  margin-right: 1em
  width: 10em
</style>
<script>
import { SET_BG_IMAGE, ADD_BOOKING_REQUEST, SET_ERROR_MESSAGE } from '@/store/mutation-types'
import store from '.././store'
export default {
  name: 'SearchBar',

  data () {
    return {
      currentLocation: {},
      detailsDialog: false,
      searchDialog: true,
      searchField: '',
      displayData: [],
      backgroundImage: '',
      selectedImage: 0,
      sizeFilterSelections: [
        { value: 250, text: '> 250 qm' },
        { value: 500, text: '> 500 qm' },
        { value: 750, text: '> 750 qm' }
      ],
      sizeFilter: null,
      equipmentFilter: [],
      technologyFilter: [],
      servicesFilter: [],
      showList: false,
      textFieldProps: {
        outlined: true,
        dense: true,
        clearable: true,
        hideDetails: 'auto'
      },
      dateProps: {
      },
      timeProps: {
        format: '24hr'
      },
      bookRoomDialog: false,
      bookingComment: '',
      bookingCount: '5',
      bookingStart: new Date(),
      bookingEnd: new Date()
    }
  },
  methods: {
    saveBookingRequest () {
      this.$store.commit(ADD_BOOKING_REQUEST, {
        roomId: this.currentLocation.id,
        room: this.currentLocation,
        start: this.bookingStart,
        end: this.bookingEnd,
        comment: this.bookingComment,
        count: this.bookingCount,
        user: this.user,
        bookingDate: new Date(),
        answerDate: null,
        messages: []
      })
      this.snack('Buchungsanfrage abgeschickt')
      this.bookRoomDialog = false
      // router.push({ name: 'management.dashboard' })
    },
    openBookRoomDialog () {
      this.bookingComment = ''
      this.bookingStart = new Date()
      this.bookingEnd = new Date()
      this.bookRoomDialog = true
      // this.currentLocation
      // const roomId = this.currentLocation.roomId
    },
    closeBookRoomDialog () {
      this.bookRoomDialog = false
    },
    snack (message) {
      this.$store.commit(SET_ERROR_MESSAGE, { message: message })
    },
    initiateSearch () {
      this.showList = true
      this.displayData = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, room] of Object.entries(this.data)) {
        if (room.name.toLowerCase().includes(this.searchField.toLowerCase())) {
          this.displayData = [...this.displayData, room]
          continue
        } else if (room.postcode.toLowerCase().includes(this.searchField.toLowerCase())) {
          this.displayData = [...this.displayData, room]
          continue
        } else if (room.city.toLowerCase().includes(this.searchField.toLowerCase())) {
          this.displayData = [...this.displayData, room]
          continue
        }
        var foundInRecommendations = false
        for (const item of room.recommendedFor) {
          if (item.toLowerCase().includes(this.searchField.toLowerCase())) {
            foundInRecommendations = true
            break
          }
        }
        if (foundInRecommendations) {
          this.displayData = [...this.displayData, room]
          continue
        }
      }
    },
    filterEquipment (room) {
      if (this.equipmentFilter.length > 0) {
        var found = false
        if (room.equipment) {
          for (const [item] in this.equipmentFilter) {
            for (const [eq] in room.equipment) {
              if (room.equipment[eq].toLowerCase().includes(this.equipmentFilter[item].toLowerCase())) {
                found = true
                break
              }
            }
            if (!found) {
              break
            }
          }
        }
        return found
      }
      return true
    },
    filterTechnology (room) {
      if (this.technologyFilter.length > 0) {
        var found = false
        if (room.technology) {
          for (const [item] in this.technologyFilter) {
            for (const [tech] in room.technology) {
              if (room.technology[tech].toLowerCase().includes(this.technologyFilter[item].toLowerCase())) {
                found = true
                break
              }
            }
            if (!found) {
              break
            }
          }
        }
        return found
      }
      return true
    },
    filterServices (room) {
      if (this.servicesFilter.length > 0) {
        var found = false
        if (room.services) {
          for (const [item] in this.servicesFilter) {
            for (const [service] in room.services) {
              if (room.services[service].toLowerCase().includes(this.servicesFilter[item].toLowerCase())) {
                found = true
                break
              }
            }
            if (!found) {
              break
            }
          }
        }
        return found
      }
      return true
    },
    filterSize (room) {
      if (this.sizeFilter) {
        if (room.room) {
          return parseInt(room.room.space) > parseInt(this.sizeFilter)
        }
        return false
      }
      return true
    },
    filterRooms () {
      this.displayData = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, room] of Object.entries(this.data)) {
        if (this.filterEquipment(room) && this.filterTechnology(room) && this.filterServices(room) && this.filterSize(room)) {
          this.displayData = [...this.displayData, room]
        }
      }
    },
    closeLocationDetails () {
      this.$store.commit(SET_BG_IMAGE, null)
      this.detailsDialog = false
    },
    openLocationDetails (item) {
      if (item.images && item.images.length > 0) {
        this.$store.commit(SET_BG_IMAGE, 'images/rooms/' + item.images[0])
      }
      this.currentLocation = item
      this.detailsDialog = true
      this.selectedImage = 0
    }
  },
  computed: {
    user () {
      if (store.state?.user !== null) {
        return store.state?.user
      }
      return null
    },
    data () {
      return store.state?.roomData ? store.state?.roomData : []
    },
    currentLocationContact () {
      return this.currentLocation.contact.contact ? this.currentLocation.contact.contact.replace('"', '').replace('\n', '<br>') : ''
    }
  },
  watch: {
    detailsDialog (val) {
      this.searchDialog = !val
    }
  }
}
</script>
