import Vue from 'vue'
import Vuex from 'vuex'

import { SET_BG_IMAGE, SET_USER_DATA, USER_LOGOUT, ADD_BOOKING_REQUEST, SET_ERROR_MESSAGE, ADD_MESSAGE, DECLINE_RESERVATION, CONFIRM_RESERVATION, DELETE_RESERVATION } from '@/store/mutation-types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bgimage: null,
    user: null,
    error: null,
    roomData: [
      {
        id: 1,
        recommendedFor: ['Schulungen', 'Konferenzen', 'Konzerte'],
        name: 'Elias-Kuppelsaal',
        postcode: '10437',
        city: 'Berlin',
        street: 'Göhrener Str. 11',
        contact: {
          name: 'Mario Gugeler',
          contact: 'Küster, Leiter Konzertbüro. Sprechzeiten: Mo 13-16, Mi 14-19, Do 9-12',
          phone: '030 445 77 45',
          mail: 'gemeindebuero@ekpn.de'
        },
        price: {
          threeHours: '15',
          day: '20',
          month: '400'
        },
        images: [
          'Elias-Kuppelsaal_1.jpg', 'Elias-Kuppelsaal_2.jpg', 'Elias-Kuppelsaal_3.jpg'
        ]
      },
      {
        id: 2,
        recommendedFor: ['Konzerte', 'Vorträge', 'Lesungen', 'Schulungen', 'Teambuilding'],
        name: 'Gethsemanekirche',
        postcode: '10437',
        city: 'Berlin',
        street: 'Stargarder Str. 77',
        contact: {
          name: 'Mario Gugeler',
          contact: 'Küster, Leiter Konzertbüro. Sprechzeiten: Mo 13-16, Mi 14-19, Do 9-12',
          phone: '030 445 77 45',
          mail: 'gemeindebuero@ekpn.de'
        },
        price: {
          threeHours: '1200',
          day: '2000',
          month: ''
        }
      },
      {
        id: 3,
        recommendedFor: ['Vorträge', 'Lesungen', 'Orgelkonzerte', 'Tanzabende'],
        name: 'Marienkirche',
        postcode: '10178',
        city: 'Berlin',
        street: 'Karl-Liebknecht-Str. 8',
        contact: {
          name: 'Marion Kirsch',
          contact: 'Gemeindereferentin',
          phone: '(030) 24 75 95 11',
          mail: 'vermietung@marienkirche-berlin.de'
        },
        room: {
          space: '1000',
          height: '16',
          capacity: '1000',
          additional: 'Kapelle: 100 qm'
        },
        equipment: [
          'Heizung',
          'Orgel',
          'Stühle',
          'Kirchenbänke',
          'Redepult',
          'Tische',
          'Toiletten'
        ],
        technology: [
          'Beschallungsanlage',
          'Podeste',
          'Bühnenbeleuchtung',
          'Beamer und Leinwand',
          'Starkstrom (16 und 32 Ampere)'
        ],
        services: [
          'externes Catering möglich',
          'Veranstaltungstechnik',
          'Ordnerdienste',
          'Abendkasse',
          'Vorverkauf',
          'Werbung',
          'Pressearbeit'
        ],
        price: {
          threeHours: '1600',
          day: 'auf Anfrage',
          month: ''
        },
        images: [
          'Marienkirche_1.jpg', 'Marienkirche_2.jpg', 'Marienkirche_3.jpg'
        ]
      },
      {
        id: 4,
        recommendedFor: ['Vorträge', 'Konferenzen', 'Lesung', 'Schulungen'],
        name: 'Parochialkirche',
        postcode: '10179',
        city: 'Berlin',
        street: 'Klosterstr. 67',
        contact: {
          name: 'Marion Kirsch',
          contact: 'Gemeindereferentin',
          phone: '(030) 24 75 95 11',
          mail: 'vermietung@marienkirche-berlin.de'
        },
        room: {
          space: '625',
          height: '18',
          capacity: '500',
          additional: 'Eingangshalle: 80 qm'
        },
        equipment: [
          'keine Heizung (!)',
          'beweglicher Altar',
          'Redepult',
          'bewegliche Bänke',
          'Toiletten'
        ],
        technology: [
          'Beschallung',
          'Beleuchtung Kirche und Bühne',
          'Podeste',
          'Beamer und Leinwand',
          'Starkstrom (16 und 32 Ampere) bis ca. 60 kW'
        ],
        services: [
          'externes Catering möglich',
          'Ordnerdienste',
          'Abendkasse',
          'Werbung',
          'Pressearbeit'
        ],
        price: {
          threeHours: '400',
          day: '1000',
          month: ''
        },
        images: [
          'Parochialkirche_1.png', 'Parochialkirche_2.png', 'Parochialkirche_3.png', 'Parochialkirche_4.png'
        ]
      },
      {
        id: 5,
        recommendedFor: ['Orgelkonzert', 'Klavierkonzert', 'Empfang', 'Konferenzen', 'Lesung', 'Vorträge'],
        name: 'Georgensaal (im Kirchenforum)',
        postcode: '10179',
        city: 'Berlin',
        street: 'Klosterstr. 67',
        contact: {
          name: 'Marion Kirsch',
          contact: 'Gemeindereferentin',
          phone: '(030) 24 75 95 11',
          mail: 'vermietung@marienkirche-berlin.de'
        },
        room: {
          space: '145',
          capacity: '80',
          additional: 'zwei kleinere Räume hinzubuchbar'
        },
        equipment: [
          'barrierefrei',
          'Orgel und Flügel',
          'Redepult',
          '95 blaue Holzstühle',
          '14 Tische a 0.80 x 1,60 m',
          '5 Stehtische',
          'Kaffeegeschirr und Gläser für ca. 60 Personen',
          'Garderoben in deen Einbauschränken vorhannden',
          'Toiletten'
        ],
        technology: [
          'Beschallung',
          'Verdunkelung durch Vorhänge',
          'Flipchart',
          'Beamer und Leinwand',
          'Pinnwände',
          'kostenloses WLAN'
        ],
        services: [
          'externes Catering möglich',
          'Technik / Konferenztechnik',
          'Pausenversorgung mit Getränken möglich'
        ],
        price: {
          threeHours: '200',
          day: '300',
          month: ''
        },
        images: [
          'Georgensaal_1.png', 'Georgensaal_2.png', 'Georgensaal_3.png'
        ]
      },
      {
        id: 6,
        recommendedFor: ['Orgelkonzert', 'Klavierkonzert', 'Vorträge', 'Schulungen'],
        name: 'Passionskirche',
        postcode: '10961',
        city: 'Berlin',
        street: 'Marheinekepl. 1',
        contact: {
          name: 'Uschi Theis',
          contact: 'Akanthus Kulturmanagement',
          phone: '(030) 694 012 41 ',
          mail: 'info@akanthus.de'
        },
        room: {
          space: '300',
          capacity: '700',
          additional: 'zahlreiche weitere Räume vorhanden - Grundriss: https://www.akanthus.de/images/pdf/PK%20Grundriss%20EG.pdf'
        },
        equipment: [
          'Orgel und Flügel',
          'Stühle',
          'Redepult',
          'Kirchenbänke',
          'Toiletten',
          'klimatisiert (Fußbodenheizung)'
        ],
        technology: [
          'Tonanlage',
          'Lichtanlage',
          'Bühne',
          'Beamer und Leinwand',
          'Starkstrom',
          'Flipchart',
          'kostenloses W-WLAN'
        ],
        price: {
          threeHours: '600',
          day: '1400',
          month: ''
        },
        images: [
          'Passionskirche_1.jpg', 'Passionskirche_2.jpg', 'Passionskirche_3.jpg'
        ]
      },
      {
        id: 7,
        recommendedFor: ['Sitzungen', 'Konzert', 'Lesung', 'Klavierkonzert', 'Screening'],
        name: 'Gemeindesaal (im Familienzentrum)',
        postcode: '10961',
        city: 'Berlin',
        street: 'Nostitzstr. 6–7',
        contact: {
          name: 'Uschi Theis',
          contact: 'Akanthus Kulturmanagement',
          phone: '(030) 694 012 41 ',
          mail: 'info@akanthus.de'
        },
        room: {
          space: '100',
          capacity: '70',
          additional: 'Grundriss: https://www.akanthus.de/images/pdf/Grundriss%20SAAL.pdf'
        },
        equipment: [
          'Parkettfußboden',
          'Garderobe',
          'Moderne Küche',
          'Steinway-O-Flügel',
          'Toiletten'
        ],
        technology: [
          'Verdunkelung durch Vorhänge',
          'Beamer und Leinwand'
        ],
        price: {
          threeHours: '80',
          day: '200',
          month: ''
        },
        images: [
          'Gemeindesaal_1.png', 'Gemeindesaal_2.png'
        ]
      }
    ],
    reservations: [
      {
        id: '8d124b2b-75e9-2da9-cbee-17f38ac877a9',
        comment: 'Hallo Eigentümer',
        confirmed: null,
        answerDate: null,
        count: '16',
        end: 'Mon May 03 2022 12:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)',
        start: 'Mon May 04 2022 11:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)',
        roomId: '3',
        user: { user: 'Demo Benutzerin' },
        bookingDate: new Date().setHours(new Date().getHours() - 20),
        messages: [
          {
            from: 'owner',
            date: new Date().setHours(new Date().getHours() - 10),
            message: 'Hallo, ich möchte mich mit meinem Verein zu einer Sitzung treffen. Hat der Raum ein Whiteboard?'
          },
          {
            from: 'user',
            date: new Date().setHours(new Date().getHours() - 9),
            message: 'Ja, hat er. Wir haben ein fest installiertes Whiteboard und ein weiteres auf Rollen. Außerdem steht in dem Raum eine portable Stellwand.'
          },
          {
            from: 'owner',
            date: new Date().setHours(new Date().getHours() - 8),
            message: 'Wunderbar, das freut mich. Gerne möchte ich den Raum nutzen.'
          },
          {
            from: 'user',
            date: new Date().setHours(new Date().getHours() - 7),
            message: 'Das freut mich. Ich schaue zeitnat in unseren internen Kalender und melde mich dann wieder.'
          }
        ],
        room:
        {
          id: 3,
          name: 'Marienkirche',
          postcode: '10178',
          city: 'Berlin',
          street: 'Karl-Liebknecht-Str. 8',
          contact: {
            name: 'Marion Kirsch',
            contact: 'Gemeindereferentin',
            phone: '(030) 24 75 95 11',
            mail: 'vermietung@marienkirche-berlin.de'
          },
          room: {
            space: '1000',
            height: '16',
            capacity: '1000',
            additional: 'Kapelle: 100 qm'
          },
          equipment: [
            'Heizung',
            'Orgel',
            'Stühle',
            'Kirchenbbänke',
            'Redepult',
            'Tische',
            'Toiletten'
          ],
          technology: [
            'Beschallungsanlage',
            'Podeste',
            'Bühnenbeleuchtung',
            'Beamer und Leinwand',
            'Starkstrom (16 und 32 Ampere)'
          ],
          services: [
            'externes Catering möglich',
            'Veranstaltungstechnik',
            'Ordnerdienste',
            'Abendkasse',
            'Vorverkauf',
            'Werbung',
            'Pressearbeit'
          ],
          price: {
            threeHours: '1600',
            day: 'auf Anfrage',
            month: ''
          },
          images: [
            'Marienkirche_1.jpg', 'Marienkirche_2.jpg', 'Marienkirche_3.jpg'
          ]
        }
      }
    ]
  },
  mutations: {
    [SET_ERROR_MESSAGE] (state, { message }) { state.error = message },
    [SET_BG_IMAGE] (state, data) { state.bgimage = data },
    [SET_USER_DATA] (state, data) { state.user = data },
    [USER_LOGOUT] (state) { state.user = null },
    [ADD_MESSAGE] (state, data) {
      const allRes = state.reservations
      for (const resId in allRes) {
        if (allRes[resId].id === data.resId) {
          delete data.resId
          allRes[resId].messages.push(data)
          state.reservations = allRes
          return true
        }
      }
      return false
    },
    [DELETE_RESERVATION] (state, resId) {
      const allRes = state.reservations
      for (const id in allRes) {
        if (allRes[id].id === resId) {
          allRes.splice(id, 1)
          state.reservations = allRes
          return true
        }
      }
      return false
    },
    [DECLINE_RESERVATION] (state, resId) {
      const allRes = state.reservations
      for (const id in allRes) {
        if (allRes[id].id === resId) {
          allRes[id].confirmed = false
          allRes[id].answerDate = new Date()
          state.reservations = allRes
          return true
        }
      }
      return false
    },
    [CONFIRM_RESERVATION] (state, resId) {
      const allRes = state.reservations
      for (const id in allRes) {
        if (allRes[id].id === resId) {
          allRes[id].confirmed = true
          allRes[id].answerDate = new Date()
          state.reservations = allRes
          return true
        }
      }
      return false
    },
    [ADD_BOOKING_REQUEST] (state, data) {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      data.id = (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
      data.confirmed = null
      state.reservations.push(data)
    }
  },
  actions: {
  },
  modules: {
  }
})
