<template>
 <v-container id="blog">
   <v-row justify="center" class="mt-8">
    <v-col cols="12" lg="8" md="10">
      <v-card elevation="0" class="mb-3" style="background-color: transparent">
        <v-card-title class="title">
          <v-img src="@/assets/TausendTüren_Logo-01.png"></v-img>
        </v-card-title>
      </v-card>

      <v-card class="pa-4">
        <v-card-title>
          <h1>Impressum</h1>
        </v-card-title>
        <v-card-text class="mt-4">
          <p>
            <b>TausendTüren</b> ist eine Marke der Digital.Wolff, Plötz & Co GmbH.<br><br>
            Angaben gemäß § 5 TMG Digital.Wolff, Plötz & Co GmbH<br>
            Geschäftsführer: Dr. Martin C. Wolff, Henryk Plötz Steuernummer: 30/262/50557<br><br>
            Klosterstraße 66 10179 Berlin-Mitte <a href="mailto:info@digitalwolff.de">info@digitalwolff.de</a>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
   </v-row>
 </v-container>
</template>

<style lang="stylus">
.title
  color: #005F8C
</style>
<script>

export default {
  name: 'Blog',
  components: {
  },

  data () {
    return {
    }
  }
}
</script>
