<template>
 <v-container id="contact">
   <v-row justify="center" class="mt-8">
    <v-col cols="12" lg="8" md="10">
      <v-card elevation="0" class="mb-3" style="background-color: transparent">
        <v-card-title>
          <v-img src="@/assets/TausendTüren_Logo-01.png"></v-img>
        </v-card-title>
      </v-card>

      <v-card class="pa-4">
        <v-card-title>
          <h1>Kontakt</h1>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row no-gutters>
            <v-col cols="10" md="6">
              <v-text-field
                v-model="name"
                name="name"
                label="Name"
                :persistent-hint="false"
                outlined
                dense
                class="mr-2"
              />
            </v-col>
            <v-col cols="10" md="6">
              <v-text-field
                v-model="mail"
                name="email"
                label="E-Mail"
                :persistent-hint="false"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="comment"
                name="comment"
                label="Ihre Botschaft"
                :persistent-hint="false"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="mt-0" v-if="messageSend">
          Vielen Dank für Ihre Nachricht. Wir melden uns schnellstmöglich bei Ihnen.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="mail = ''; name = ''; comment = ''; messageSend = true;"
          >
            Senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
   </v-row>
 </v-container>
</template>

<style lang="stylus">

</style>
<script>

export default {
  name: 'Contact',
  components: {
  },

  data () {
    return {
      mail: '',
      comment: '',
      name: '',
      messageSend: false
    }
  }
}
</script>
