<template>
  <v-app class="overflow-hidden">
   <v-main :style="cssProps"><!--
      <v-container fluid v-if="this.$route.name !== 'Home'">
        <router-view />
      </v-container> v-else-->
      <v-container fluid class="pa-0 ma-0" style="overflow: hidden;">
        <router-view />
      </v-container>
      <v-snackbar :value="snackbarVisible" :timeout="-1" color="primary" elevation="4">
        {{ $store.state.error }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="dismissError"
          >
            Meldung schliessen
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-footer
      app
      color="primary"
      padless
      absolute
      style="z-index: 224!important;"
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          v-for="link in footerLinks"
          v-bind:key="link.key"
          color="white"
          :to="link.router"
          text
          class="my-2 px-2"
        >
          {{ link.label }}
        </v-btn>
        <v-btn
          v-if="userLoggedIn"
          color="white"
          text
          class="my-2 px-2"
          @click.stop="logout"
        >
          Logout
        </v-btn>
        <router-link
          v-if="userLoggedIn"
          :to="{name: 'management.dashboard.owner'}"
        >
          <v-btn
            color="white"
            text
            class="my-2 px-2"
          >
            Verwaltung
          </v-btn>
        </router-link>
      </v-row>
    </v-footer>
  </v-app>
</template>
<style lang="stylus">
.v-main
  background-size: cover
</style>
<script>

import { USER_LOGOUT, SET_ERROR_MESSAGE } from '@/store/mutation-types'
import store from './store'
import router from './router'
export default {
  name: 'App',

  components: {
  },

  data () {
    return {
      footerLinks: [
        {
          router: { name: 'home' },
          label: 'Home'
        },
        {
          router: { name: 'login' },
          label: 'Anmelden'
        },
        {
          router: { name: 'register' },
          label: 'Registrieren'
        },
        {
          router: { name: 'blog' },
          label: 'Blog'
        },
        {
          router: { name: 'contact' },
          label: 'Kontakt'
        },
        {
          router: { name: 'imprint' },
          label: 'Impressum'
        }
      ]
    }
  },
  computed: {
    cssProps () {
      // if (this.$route.name === 'management.dashboard') {
      //   return {}
      // }
      const path = store.state?.bgimage === null ? 'images/background.jpg' : store.state.bgimage
      return {
        backgroundImage: `url(${require('@/assets/' + path)})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center center'
      }
    },
    userLoggedIn () {
      return store.state?.user !== null
    },
    snackbarVisible: function () {
      return !!this.$store.state.error
    }
  },
  methods: {
    logout: function () {
      // store.state?.bgimage
      this.$store.commit(USER_LOGOUT)
      router.push({ name: 'home' })
    },
    dismissError: function () {
      this.$store.commit(SET_ERROR_MESSAGE, { message: null })
    }
  }
}
</script>
